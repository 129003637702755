
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































































.cinema-ticket {
  position: relative;
}

.cinema-ticket__head {
  position: relative;
  color: #fff;
  text-align: center;

  h2 {
    margin-top: 27px;
    margin-bottom: 70px;
  }

  .cinema-ticket__heart-voo-light {
    position: absolute;
    fill: $c-pink-lightest;
    transform: rotate(-8.58deg);
    bottom: 70px;
    left: -30px;
    width: 45px;
    height: auto;

    @include mq(l) {
      bottom: 0;
      left: -40px;
      width: inherit;
      max-width: 100px;
    }
  }
}

.ticket-texts__title {
  margin: 20px 0;
}

.ticket {
  @extend %color-light;

  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: auto;
  padding-right: 4rem;
  background: rgba(255, 255, 255, 5%);
  border: 1px solid rgba(255, 255, 255, 20%);
  border-radius: 16px;
  backdrop-filter: blur(17px);

  @include mq($until: l) {
    flex-direction: column;
    padding-right: 0;
  }
}

.ticket-picture {
  @include mq($until: l) {
    display: block;
    width: 100%;
    margin: auto;
    margin-bottom: 0;
  }

  @include mq(l) {
    max-width: 400px;
    transition: transform 0.2s ease-in-out;
  }
}

.ticket-texts {
  width: 80%;
  padding: 40px 20px;

  .ticket-headline {
    font-size: 14px;
  }

  a {
    margin-top: 20px;
  }

  .ticket-texts__title {
    margin-bottom: 0;

    @include mq($until: l) {
      font-size: 28px;
    }

    @include mq(l) {
      font-size: 42px;
    }
  }

  @include mq($until: l) {
    width: inherit;
    margin: auto;
    padding: 3.4rem;
  }

  @include mq(l) {
    width: 64%;
    margin-left: auto;
  }
}
