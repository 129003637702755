
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































.promo-carousel-card {
  --item-width: 30rem;

  position: relative;
  width: 80vw;
  max-width: var(--item-width);
  aspect-ratio: 3/4;
  color: $c-midnight-darkest;
  font-family: $ff-alt;
  background: $white;
  border-radius: 1.2rem;
  box-shadow: -20px 40px 90px rgba(0, 0, 0, 50%);
}

.promo-carousel-card__overlay {
  @extend %fw-bold;
  @include get-all-space;

  @include mq(l) {
    @include get-all-space;

    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    background: var(--c-accent);
    border-radius: 1rem;
    opacity: 0;
    transition: opacity 0.3s $ease-out-cubic;

    &:hover,
    &:focus-within {
      opacity: 1;
    }
  }
}

.promo-carousel-card__overlay__title {
  @extend %fw-black;
  @extend %text-center;
  @extend %text-uppercase;
  @include center-x;

  bottom: $spacing;
  width: 100%;
  font-size: 1.3rem;

  @include mq(l) {
    position: static;
    max-width: 10em;
    margin: 0 auto;
    font-size: 3.5rem;
    line-height: 5rem;
    transform: none;
  }
}

.promo-carousel-card__overlay__btn {
  display: none;

  span {
    display: none;
  }

  &::after {
    @include get-all-space;

    content: '';
    z-index: 2;
  }

  @include mq(l) {
    position: static;
    display: block;
    color: $white;
    transform: none;
  }
}

.promo-carousel-card-inner {
  @extend %fw-semi;
  @extend %text-center;

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: $spacing * -3;
  padding: 0 $spacing;
}

.promo-carousel-card__hero {
  position: relative;
  flex: 1;
  transition: opacity 0.3s $ease-out-cubic;

  img {
    @include image-fit(contain);
  }

  @include mq(l) {
    .promo-carousel-card__overlay:hover + div &,
    .promo-carousel-card__overlay:focus-within + div & {
      opacity: 0;
    }
  }
}

.promo-carousel-card__number {
  @extend %fw-black;

  position: relative;
  z-index: 1;
  font-size: 9.5rem;
  line-height: 0.9;
  text-shadow: 0.5rem 0.5rem 1rem rgba($black, 20%);

  &.small {
    font-size: 7rem;
  }

  .promo-carousel-card__hero + & {
    margin-top: $spacing * -3;
  }

  @include mq(l) {
    font-size: 14rem;
    line-height: 13rem;

    &.small {
      font-size: 10rem;
      line-height: 9.5rem;
    }
  }
}

.promo-carousel-card__title {
  @extend %fw-black;
  @extend %text-uppercase;

  @include mq(l) {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}

.promo-carousel-card__subtitle {
  color: $c-pink-medium;
}

.promo-carousel-card__picture {
  position: relative;
  z-index: 1;
  width: 22rem;
  margin: auto;

  .promo-carousel-card__hero + & {
    margin-top: $spacing * -3;
  }
}

.promo-carousel-card__graphics {
  @include get-all-space;

  z-index: 0;
  overflow: hidden;
  border-radius: 1.2rem;
  pointer-events: none;
}

.promo-carousel-card__graphics__splatter {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 34rem;
  height: auto;
  aspect-ratio: 1;
  fill: var(--c-accent);
  transform: translate(-20%, -30%);

  // Yellow
  [data-color='1'] & {
    transform: translate(-15%, -30%) rotate(-90deg);
  }

  // Blue
  [data-color='2'] & {
    transform: translate(-20%, -30%) rotate(-180deg);
  }

  // Light pink
  [data-color='3'] & {
    transform: translate(-15%, -54%) rotate(-90deg);
  }

  // Pink medium
  [data-color='4'] & {
    transform: translate(-25%, -60%) rotate(-150deg);
  }

  @include mq(l) {
    width: 50rem;

    // Pink medium
    [data-color='4'] & {
      transform: translate(-11%, -45%) rotate(-150deg);
    }
  }
}

.promo-carousel-card__graphics__gift {
  position: absolute;
  top: -10%;
  left: -60%;
  width: 80rem;
  height: auto;
  aspect-ratio: 1;
  fill: rgba($c-pink-medium, 10%);
  transform: rotate(10deg);

  // Yellow
  [data-color='1'] & {
    top: -25%;
    left: -95%;
  }

  // Blue
  [data-color='2'] & {
    top: 2%;
    left: -53%;
  }

  // Light pink
  [data-color='3'] & {
    top: -48%;
    left: -57%;
  }

  // Pink medium
  [data-color='4'] & {
    top: -14%;
    left: -71%;
  }

  @include mq(l) {
    width: 125rem;
  }
}
