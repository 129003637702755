
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































.card {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $white;
  background-color: rgba($white, 0.2);
  border-radius: 1rem;
  box-shadow: $box-shadow-lvl-1;

  &:hover {
    .card__thumbnail {
      transform: scale(1.1);
    }
  }
}

.card__thumbnail-container {
  // prettier-ignore
  @include fluid(height, (s: 130px, l: 195px));

  display: block;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.card__thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform $ease-out-cubic 500ms;
}

.card__body {
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
}

.card__title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

.card__subtitle {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.2;
}

.card__label {
  @include center-xy;

  top: 0;
}
