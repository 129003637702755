
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































































































.trailer {
  color: #fff;
  text-align: center;

  @include mq($until: l) {
    padding-top: 20px !important;
  }
}

.trailer__media {
  position: relative;

  &::before {
    position: absolute;
    top: -116px;
    left: -150px;
    display: block;
    content: '';
    width: 258.68px;
    height: 257.24px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(62, 104, 170, 0.7) 0%,
      rgba(112, 0, 255, 0) 100%
    );
    opacity: 0.6;
    filter: blur(60px);
    transform: rotate(36.46deg);

    @include mq(l) {
      top: -184px;
      left: -150px;
      width: 445.68px;
      height: 584.24px;
    }
  }
}

.trailer__title {
  margin-top: 2.7rem;
  margin-bottom: 3rem;

  @include mq(l) {
    margin-top: 3.7rem;
    margin-bottom: 6rem;
  }
}

.trailer__subtitle {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.video__image {
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

.trailer__video {
  position: relative;
  overflow: hidden;
  max-width: 1128px;
  margin: auto;
  border-radius: 20px;
  cursor: pointer;
  aspect-ratio: 16/9;

  &:hover {
    .video__image {
      transform: scale(1.04);
    }
  }
}

.video__button {
  @extend %fw-semi;
  @include center-xy;

  display: flex;
  flex-direction: column;

  .video__button-icon {
    width: 100px;
    margin-bottom: 1rem;
  }

  @include mq($until: l) {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

.trailer__icons {
  @include get-all-space;

  pointer-events: none;
  z-index: 20;
}

.trailer__icons .icon {
  position: absolute;

  &.trailer__icons--01 {
    top: -91px;
    left: -166px;
    width: 115.88px;
    height: 90.62px;
    transform: rotate(-21.95deg);
    fill: #da83b5;

    @include mq($until: l) {
      display: none;
    }
  }

  &.trailer__icons--02 {
    bottom: 100px;
    left: -133px;
    width: 108.92px;
    height: 103.51px;
    transform: rotate(-5.29deg);
    fill: #0dbaba;

    @include mq($until: l) {
      bottom: -20px;
      left: -23px;
      width: 48.92px;
      height: 48.92px;
    }
  }

  &.trailer__icons--03 {
    top: -170px;
    right: 20px;
    width: 93px;
    height: 92px;
    transform: rotate(15.15deg);
    fill: #faa817;

    @include mq($until: l) {
      top: -102px;
      right: 0;
      width: 37px;
      height: 54px;
    }
  }

  &.trailer__icons--04 {
    top: 50%;
    right: 40px;
    transform: rotate(15.36deg) translateY(-50%);
    width: 82px;
    height: 97px;
    fill: #0dbaba;

    @include mq($until: l) {
      right: -12px;
      width: 40px;
    }
  }

  &.trailer__icons--05 {
    right: -157px;
    bottom: 10px;
    width: 125px;
    height: 122px;
    transform: rotate(10.17deg);
    fill: #d4007a;

    @include mq($until: l) {
      display: none;
    }
  }
}
