
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































































































































































































































































































































































































































$cards-accent: (
  0: $c-pink-medium,
  1: $c-mandarine,
  2: $c-cyan-contrast,
  3: $c-gift-color-a,
  4: $c-pink-medium,
);

.promo-carousel {
  position: relative;
}

.promo-carousel__intro {
  @extend %text-center;

  color: $white;
}

.promo-carousel__intro__text {
  max-width: 30em;
  margin: auto;
}

.promo-carousel__cards-outer {
  overflow-x: scroll;
  margin-top: $spacing * 2;
  margin-bottom: $spacing * 2;
  padding-top: $spacing * 3;

  @include mq(l) {
    position: relative;
    overflow-x: visible;
  }
}

.promo-carousel__cards {
  @extend %list-nostyle;

  display: flex;

  @include mq(l) {
    position: relative;
    z-index: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60rem;
  }
}

.promo-carousel__card {
  --item-width: 30rem;

  position: relative;
  flex-shrink: 0;

  & + & {
    margin-left: $spacing;
  }

  @include mq(l) {
    --item-width: 44rem;

    position: absolute;
    height: 60rem;
    aspect-ratio: unset;
    will-change: transform, left;

    & + & {
      margin-left: 0;
    }
  }

  // Set accent colors
  @each $index, $color in $cards-accent {
    &[data-color='#{$index}'] {
      --c-accent: #{$color};
    }
  }
}

.promo-carousel__controls {
  gap: 50rem;
  margin: $spacing * -5 0 5rem;

  &.tv-slider-controls {
    justify-content: center;
  }
}

.promo-carousel__button {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.promo-carousel__speedlines {
  width: 100%;
}
