
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        












































































































































.entertain {
  background-color: $c-promo-card;
}

::v-deep {
  section {
    &:not(section.hero),
    // eslint-disable-next-line prettier/prettier
    &.entertain-yourself__header-slider .hero__slides__item__picture-outer--slider {
      background-color: $c-promo-card !important;
    }

    .hero__slides__item__content {
      @include mq($until: l) {
        background-color: $c-promo-card !important;
      }
    }
  }

  .channels-hero {
    min-height: 80vh;

    .channels-bg__item {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
      }
    }

    @include mq($until: l) {
      margin-top: inherit;
    }
  }

  .entertain-card {
    overflow: inherit !important;

    .card-small__items__item {
      background: rgba(255, 255, 255, 5%);
      border: 1px solid rgba(255, 255, 255, 20%);
      backdrop-filter: blur(17px);

      .card-small-item-inner {
        color: #fff;
      }

      &:hover {
        .card-small-item__cta {
          color: #fff;
          background-image: linear-gradient(#d4007a, #d4007a),
            linear-gradient(#fff, #fff);
        }
      }
    }

    .card-small-item__title {
      margin-bottom: 1rem;
      font-size: 32px;
    }

    .card-small-item__subtitle {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 14px;
      font-size: 20px;
    }

    h2 {
      margin-bottom: 40px;

      @include mq(xl) {
        margin-bottom: 80px;
      }
    }

    &::before,
    &::after {
      position: absolute;
      z-index: -1;
      pointer-events: none;
      content: '';
      display: block;
      filter: blur(60px);
      background-color: transparent !important;
      opacity: 0.6;
    }

    &::before {
      top: -152px;
      left: -7%;
      width: 445.68px;
      height: 584.24px;
      transform: rotate(36.46deg);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(62, 104, 170, 0.7) 0%,
        rgba(112, 0, 255, 0) 100%
      );
    }

    &::after {
      top: 68px;
      right: -8.42%;
      left: -19%;
      height: 900px;
      transform: rotate(-36.46deg);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(212, 16, 122, 0.5) 0%,
        rgba(212, 16, 122, 0) 100%
      );
    }
  }

  .tv-rack {
    .label--outline {
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: 1px;
      background: #217078 !important;
      border-color: #217078 !important;

      @include mq($until: l) {
        margin-bottom: 2em;
        padding: 0.5rem 1.33rem !important;
        font-size: 1.2rem;
      }
    }

    .flickity-page-dots {
      @include mq($until: l) {
        margin-top: 0;
      }
    }

    .dot.is-selected {
      background-color: transparent;
    }

    .tv-rack__equalizer {
      @include mq(l) {
        height: 8rem;
      }
    }

    .tv-rack__title {
      margin-top: 2rem;
    }

    @include mq($until: l) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .label--outline {
    width: fit-content;
    margin-bottom: 12px;

    &.label--white {
      padding: 9px 14px;
    }

    @include mq(l) {
      margin-bottom: 32px;
    }
  }

  .entertain-yourself__header {
    position: relative;
    z-index: 1;

    .hero__slides {
      z-index: 10;
    }

    .hero__slides__item__title {
      @include mq(l) {
        margin-bottom: 33px;
      }
    }

    .hero__slides__item__icon {
      margin-bottom: 22px;

      .icon {
        max-width: initial;
        height: 3rem;
      }
    }

    .hero-item__anchors {
      display: flex;
      margin-top: $spacing;

      @include mq($until: m) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
      }
    }

    .hero-item__anchor {
      @extend %fw-bold;

      display: inline-flex;
      align-items: center;
      color: $c-blue-dark;
      line-height: 1;
      text-decoration: none;

      &:first-child {
        @include mq(m) {
          margin-right: $spacing * 1.5;
        }
      }

      .icon {
        width: 4rem;
        height: 5rem;
        margin-right: 10px;
        fill: $white;

        @include mq($until: m) {
          width: 3.4rem;
        }
      }

      @include mq(m) {
        margin-top: $spacing;
        margin-bottom: $spacing * 2.5;
      }

      @include mq(l) {
        margin-top: $spacing * 2;
        margin-bottom: 0;
      }
    }

    .hero__slides__item__headline {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 580px;
      margin: auto;
      color: #fff;
      text-align: center;

      .label--outline {
        @include mq($until: l) {
          margin-bottom: 1.5rem;
        }
      }

      h2 {
        margin-top: 34px;
        margin-bottom: 28px;

        @include mq($until: l) {
          margin-top: 0;
        }
      }

      p {
        margin-top: 0;

        @include mq($until: l) {
          margin: 0;
        }
      }

      img {
        max-width: 216px;
        margin-top: 40px;

        @include mq($until: l) {
          margin: 20px 0;
        }
      }

      @include mq(l) {
        margin: 40px auto;
      }
    }

    @include mq(l) {
      &::before {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        content: '';
        display: block;
        width: 100%;
        height: 100%;

        // prettier-ignore
        background: linear-gradient(84.16deg,#000000 0%,rgba(0, 0, 0, 0) 88.5%);
      }
    }
  }

  .entertain-yourself__header-slider {
    position: relative;
    margin-top: 0;

    &::before {
      height: 62rem;
      background: linear-gradient(
        0deg,
        #081728 12.91%,
        rgba(8, 23, 40, 0) 100%
      );
    }

    .hero__slides--solo {
      @include mq($until: l) {
        margin: 40px 0;
      }
    }

    .hero__slides__item__slider-outer {
      align-items: flex-start;
    }

    .hero__slides__item__content {
      display: none;
    }

    span {
      @extend %fw-bold;
      @extend %text-uppercase;

      display: inline-block;
      padding: 0.9rem 1.4rem;
      color: $white;
      font-size: 1.1rem;
      line-height: 1;
      border: 1px solid $white;
      border-radius: 3px;

      @include mq($until: m) {
        line-height: 1.3;
      }

      @include mq(m) {
        font-size: 1.3rem;
      }
    }

    .link--pink {
      &,
      span {
        background-image: linear-gradient($c-pink-medium, $c-pink-medium),
          linear-gradient($white, $white);
      }

      &:hover,
      &:focus {
        color: $white !important;
      }
    }
  }

  @include mq($until: m) {
    .hero__slides__item__icon {
      margin-bottom: 1rem;

      .icon {
        width: 63%;
        height: auto;
      }
    }

    .hero__slides__item__title {
      margin-bottom: 2rem;
    }

    .hero__slides__item__subtitle {
      display: inline-block;
      margin: 0 0 2rem;
    }

    // Tv Channels
    .channels-hero {
      min-height: 47vh;
    }

    .channels-hero__inner {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    .channels-hero__content {
      .robbie-tv-title {
        margin-bottom: 1rem;
      }

      .robbie-tv-text {
        margin-top: 0;
      }

      .btn--no-icon {
        margin-top: 1rem;
      }
    }

    .channels-hero__content::after {
      height: 86%;
    }

    .tv-rack {
      .tv-rack__title {
        margin-top: 0;
        margin-bottom: 5rem;
      }
    }

    .cinema-ticket__head {
      h2 {
        margin-top: 1.5rem;
        margin-bottom: 4rem;
      }
    }

    .vod-highlight-inner {
      padding-top: 2rem !important;
    }

    .vod-highlight__more-infos {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 2rem;

      .label--outline,
      p {
        margin-bottom: 0;
      }
    }

    .vod-highlight__heart-filled-blue {
      display: none;
    }
  }
}

.card-small {
  ::v-deep {
    .card-small__title {
      color: #fff;
    }
  }
}

::v-deep .divertissez-voo__highlight {
  @include mq($until: m) {
    .cinema-ticket__heart-voo-light {
      top: -254px;
      right: 143px;
      width: 50px;
    }

    .vod-highlight__heart-filled-pink {
      display: none;
    }
  }

  @include mq($until: xxxl) {
    .vod-highlight__heart-voo-light {
      display: none;
    }
  }

  @include mq(m) {
    .vod-highlight__heart-filled-blue {
      display: none;
    }
  }
}
