
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























.duo-text-images__item {
  &:nth-child(even) {
    @include mq(m) {
      flex-direction: row-reverse;
    }
  }
}

.duo-text-images__item + .duo-text-images__item {
  margin-top: $spacing * 4;

  @include mq(m) {
    margin-top: $spacing * 7;
  }
}
