
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































































.league {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  @include mq(m) {
    flex-direction: row;
  }

  @include mq($until: l) {
    padding-top: 0 !important;
  }
}

.league-text {
  width: 100%;
  color: #fff;

  ::v-deep {
    .btn {
      @include mq($until: l) {
        margin-top: 0;
      }
    }
  }

  @include mq(m) {
    width: col(4.5);
  }

  @include mq(xxl) {
    width: col(3.5);
  }
}

.league-text_logo {
  max-width: 18rem;
  margin: 0 0 $spacing * 1.5;

  @include mq($until: l) {
    margin: 1rem 0 2rem;
  }
}

.league-text_title {
  margin-top: 0;
  margin-bottom: 2rem;

  @include mq($until: l) {
    margin-bottom: 1.5rem;
  }
}

.league-text_paragraph {
  margin-bottom: 4rem;

  @include mq($until: l) {
    margin-bottom: 3rem;
  }
}

.league__pictures {
  position: relative;
  width: 100%;
  height: 400px;

  &::before {
    position: absolute;
    top: -39px;
    left: 169px;
    display: block;
    width: 607px;
    height: 647px;
    content: '';
    pointer-events: none;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #3e68aa 0%,
      rgba(112, 0, 255, 0%) 100%
    );
    opacity: 0.6;
    filter: blur(60px);
    transform: rotate(36.46deg);
  }

  img {
    position: absolute;
    aspect-ratio: 7 / 10;
    object-position: center;
    object-fit: cover;
    border-radius: 6.5px;
    box-shadow: 0 8.1px 32.5px rgba(0, 0, 0, 10%);
  }

  @include mq(s) {
    height: 562px;
  }

  @include mq($until: m) {
    margin-bottom: 40px;
  }

  @include mq(m) {
    width: col(7);
    height: 440px;
  }

  @include mq(l) {
    height: 600px;
  }

  @for $i from 1 through 3 {
    img:nth-child(3n + #{$i}) {
      z-index: calc(10 - #{$i});
      width: calc(50% - (#{$i} * 3%));

      @include mq(s) {
        width: calc(47% - (#{$i} * 3%));
      }

      @if $i == 1 {
        right: 0;
        bottom: 0;
      }

      @if $i == 2 {
        top: 0;
        left: 30%;
      }

      @if $i == 3 {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}
